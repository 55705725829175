import React from 'react';

const Patents = () => {
  const patents = {
    granted: [
      {
        title: "An abrasive flow finishing device, an abrasive flow finishing process and Magnetorheological polishing fluid",
        applicationNo: "1991/DEL/2005",
        applicant: "I.I.T. Kanpur",
        inventors: ["Sunil Jha", "Vijay Kumar Jain"]
      }
    ],
    filed: [
      {
        title: "A device for magnetic Abrasive Finishing of multiple workpieces and Magnetic Abrasive Finishing Process",
        applicationNo: "2309/DEL/2008",
        applicant: "I.I.T. Kanpur",
        inventors: ["Sandeep R Nair", "J. Ramkumar", "Sunil Jha"]
      },
      {
        title: "Process of generating magnetically controlled ball end smart abrasive laden shape for finishing of 3D intricate shaped surfaces",
        applicationNo: "394/DEL/2010",
        applicant: "I.I.T. Delhi",
        inventors: ["Sunil Jha", "P. M. Pandey", "Anant K. Singh"]
      },
      {
        title: "Patent of addition on 'An improved magnetorheological finishing apparatus for stable, scalable and continuous finishing application'",
        applicationNo: "2611/DEL/2011",
        applicant: "I.I.T. Delhi",
        inventors: ["Sunil Jha", "P. M. Pandey", "Anant K. Singh"]
      },
      {
        title: "Process and System for Nano-Finishing a Surface",
        applicationNo: "201711038585",
        applicant: "I.I.T. Delhi",
        inventors: ["Sunil Jha", "Faiz Iqbal"]
      },
      {
        title: "Bending of Orthopaedic Plates",
        applicationNo: "201711038563",
        applicant: "I.I.T. Delhi",
        inventors: ["Sunil Jha", "Lalit Maini"]
      },
      {
        title: "System for Supplying Polishing Fluid",
        applicationNo: "201811010070",
        applicant: "I.I.T. Delhi",
        inventors: ["Sunil Jha", "Zafar Alam", "Dilshad A Khan"]
      },
      {
        title: "MR Finishing Tool with Electromagnet Cooling",
        applicationNo: "201811010878",
        applicant: "I.I.T. Delhi",
        inventors: ["Sunil Jha", "Dilshad A Khan", "Zafar Alam"]
      },
      {
        title: "Cup Shaped Tool Component",
        applicationNo: "201911051131",
        applicant: "I.I.T. Delhi",
        inventors: ["Sunil Jha", "Onkar Chawla", "Tarun Verma"]
      },
      {
        title: "An Automatic Tool Changer (ATC) With A Provision for Supplying Pressurized Fluid with Transmission Of Rotary Power",
        applicationNo: "202011053623",
        applicant: "I.I.T. Delhi",
        inventors: ["Sunil Jha", "Onkar Chawla", "Tarun Verma"]
      },
      {
        title: "Apparatus and Method for Performing a Milling Operation",
        applicationNo: "202111006521",
        applicant: "I.I.T. Delhi",
        inventors: ["Sunil Jha", "Onkar Chawla", "Tarun Verma"]
      },
      {
        title: "Homogenous Mixing and Controlled Ejection of Mixtures of Particulate Matter",
        applicationNo: "202111055451",
        applicant: "I.I.T. Delhi",
        inventors: ["Sunil Jha", "Onkar Chawla", "Tarun Verma"]
      }
    ]
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 my-14">
      <div className="container mx-auto px-6 py-8 flex-grow">
        <h1 className="text-3xl font-bold mb-8 text-center text-gray-900">Patents</h1>

        {/* Patent Summary Section */}
        <div className="mb-8 rounded-lg bg-white shadow-lg p-6">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Patent Summary</h2>
          <div className="flex justify-center items-center text-lg text-gray-700">
            <div className="mr-4 text-green-500 font-semibold">Filed: {patents.filed.length}</div>
            <div className="text-red-500 font-semibold">Granted: {patents.granted.length}</div>
          </div>
        </div>

        {/* Granted Patents Table */}
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4 text-center text-gray-900">Granted Patents</h2>
          <div className="w-full overflow-x-auto">
            <table className="w-full table-auto border-collapse border border-gray-600">
              <thead>
                <tr>
                  <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                    Title
                  </th>
                  <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                    Application No.
                  </th>
                  <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                    Applicant
                  </th>
                  <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                    Inventors
                  </th>
                </tr>
              </thead>
              <tbody>
                {patents.granted.map((patent, index) => (
                  <tr key={index} className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"}`}>
                    <td className="px-2 md:px-4 py-2 text-xs md:text-sm text-gray-700 text-left border border-gray-600">
                      {patent.title}
                    </td>
                    <td className="px-2 md:px-4 py-2 text-xs md:text-sm text-gray-700 text-left border border-gray-600">
                      {patent.applicationNo}
                    </td>
                    <td className="px-2 md:px-4 py-2 text-xs md:text-sm text-gray-700 text-left border border-gray-600">
                      {patent.applicant}
                    </td>
                    <td className="px-2 md:px-4 py-2 text-xs md:text-sm text-gray-700 text-left border border-gray-600">
                      {patent.inventors.join(', ')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Filed Patents Table */}
        <div>
          <h2 className="text-xl font-semibold mb-4 text-center text-gray-900">Filed Patents</h2>
          <div className="w-full overflow-x-auto">
            <table className="w-full table-auto border-collapse border border-gray-600">
              <thead>
                <tr>
                  <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                    Title
                  </th>
                  <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                    Application No.
                  </th>
                  <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                    Applicant
                  </th>
                  <th className="px-2 md:px-4 py-2 bg-blue-600 text-left text-xs md:text-sm font-medium text-white uppercase tracking-wider border border-gray-600">
                    Inventors
                  </th>
                </tr>
              </thead>
              <tbody>
                {patents.filed.map((patent, index) => (
                  <tr key={index} className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"}`}>
                    <td className="px-2 md:px-4 py-2 text-xs md:text-sm text-gray-700 text-left border border-gray-600">
                      {patent.title}
                    </td>
                    <td className="px-2 md:px-4 py-2 text-xs md:text-sm text-gray-700 text-left border border-gray-600">
                      {patent.applicationNo}
                    </td>
                    <td className="px-2 md:px-4 py-2 text-xs md:text-sm text-gray-700 text-left border border-gray-600">
                      {patent.applicant}
                    </td>
                    <td className="px-2 md:px-4 py-2 text-xs md:text-sm text-gray-700 text-left border border-gray-600">
                      {patent.inventors.join(', ')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Patents;