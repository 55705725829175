import React from "react";
import HeaderLogo from "../assets/suniljhaposter4.png";

const Header = () => {
  return (
    <header className="relative w-full h-40 overflow-hidden">
      <a 
        href="https://www.iafsm.in/"  
        target="_blank"
        rel="noopener noreferrer"
        className="absolute inset-0 w-full h-full"
      >
        <img 
          src={HeaderLogo}
          alt="Header Image"
          className="w-full h-full object-cover"
        />
      </a>
    </header>
  );
};

export default Header;
